<template>
  <v-container>
    <v-sheet rounded color="grey darken-3" class="white--text" elevation="1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="text-h4 font-weight-light">Prestations</h2>
          </v-col>
        </v-row>
        <v-row justify="center" class="px-2 px-lg-4">
          <v-col
            cols="12"
            sm="6"
            lg="4"
            v-for="card in frontend"
            :key="card.title"
          >
            <Card :card="card" />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet
      rounded
      color="grey darken-3"
      class="white--text mt-2"
      elevation="1"
    >
    </v-sheet>
    <v-lazy
      v-model="showConnex"
      :options="{
        threshold: 0.5,
      }"
      min-height="200"
      transition="scale-transition"
    >
      <v-sheet
        rounded
        color="grey darken-3"
        class="white--text mt-2"
        elevation="1"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <h2 class="text-h4 font-weight-light">Services Connexes</h2>
            </v-col>
          </v-row>
          <v-row justify="center" class="px-2 px-lg-4">
            <v-col cols="12" sm="6" lg="4">
              <CustomCard title="Déploiement">
                <template v-slot:header>
                  <div class="d-flex justify-center">
                    <v-icon color="grey darken-3" size="160"
                      >mdi-server-network</v-icon
                    >
                  </div>
                </template>
              </CustomCard>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <CustomCard title="Maintenance">
                <template v-slot:header>
                  <div class="d-flex justify-center">
                    <v-icon color="grey darken-3" size="160">mdi-wrench</v-icon>
                  </div>
                </template>
              </CustomCard>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <CustomCard title="Performance">
                <template v-slot:header>
                  <div class="d-flex justify-center">
                    <v-icon color="grey darken-3" size="160"
                      >mdi-speedometer</v-icon
                    >
                  </div>
                </template>
              </CustomCard>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-lazy>
  </v-container>
</template>

<script>
import Card from "@/components/Card";
import CustomCard from "@/components/prestas/CustomCard";
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "yellow", "green"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
  ["#1feaea", "#ffd200", "#f72047"],
];

export default {
  name: "Web",
  data() {
    return {
      frontend: [
        {
          title: "Applications Web",
          img: "web/backoffice.jpg",
          pretext: "Des solutions sur mesure pour votre entreprise",
          posttext:
            "Parce que les besoins et exigences client vont bien au delà de solutions standardisées, je développe des solutions et des web-app sur mesure pour répondre à vos besoins les plus complexes. En utilisant des technologies pensées autour du Material Design telles que Vuetify, retrouvez dans vos applications les codes modernes du développement d'applications",
        },
        {
          title: "Backend",
          img: "web/node.jpg",
          pretext: "Le serveur sur mesure",
          posttext:
            "La mise en place de serveurs back-end requiert une importante rigueur dans le choix des technologies et du hardware le supportant. J'utilise Node.JS pour tous mes projets backend depuis 2020, réalisant des architectures flexibles, légères et robustes. Grâce au TypeScript et à des technologies de validation à l'exéctution telles que Zod, votre backend est robuste, sûr et performant",
        },
        {
          title: "Applications Mobiles",
          img: "web/mobile.jpg",
          pretext: "Performance, feeling, multiplateforme",
          posttext:
            "L'omniprésence du mobile implique l'augmentation des besoins en développement mobile multiplateforme. Si le code natif possède des avantages et des spécificités indéniables, l'immense majorité des besoins sont aujourd'hui couverts par des solutions multiplateformes telles que React Native. Loin de la WebView encapsulée, React Native permet la traduction d'élements React en code natif",
        },
        {
          title: "Solutions Cloud",
          img: "web/aws2.png",
          pretext: "L'architecture Cloud au service de votre besoin",
          posttext:
            "Les applications d'aujourd'hui et de demain ne peuvent se passer de solutions cloud.<br/>AWS Certified Cloud Practitionner (06/2022) et Solutions Architect <span class='font-italic'>(en cours d'obtention)</span>, je vous propose mon expertise pour la conception d'architectures robustes, sûres et économes.",
        },
        {
          title: "DevOps",
          img: "web/devops.jpg",
          pretext: "Le développement au service du déploiement",
          posttext:
            "Disposer d'une solution de CI/CD fonctionnelle est indispensable lors du développement et de la maintenance d'une application. En mettant à profit les outils GitLab, je mets en place des solutions d'intégration et déploiement continus permettant de décharger votre DSI de coûteuses et répétitives manipulations.",
        },
        {
          title: "Intégration Bas-Niveau",
          img: "web/raspberry.jpg",
          pretext: "L'intégration machine à votre portée",
          posttext:
            "Mon expérience en R&D électronique me permet de répondre à vos besoins les plus exotiques d'intégration bas niveau. Des matériels exigent parfois une interface complexe avec le reste de votre système informatique. Votre serveur nécessite une intégration UART, I2C ? SPI? Vous êtes au bon endroit",
        },
      ],
      width: 5,
      radius: 10,
      padding: 0,
      lineCap: "round",
      gradient: gradients[6],
      value: [0, 1, 2, 4, 1, 3, 3, 5, 5, 7, 5, 8, 5, 8, 10],
      gradientDirection: "top",
      gradients,
      fill: false,
      type: "trend",
      autoLineWidth: true,
      showConnex: false,
    };
  },
  components: {
    Card,
    CustomCard,
  },
};
</script>

<style lang="scss" scoped>
.row {
  &__container {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
  }
}
.italic{
  font-style: italic;
}
</style>