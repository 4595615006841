<template>
    <v-card height="100%">
            <slot name="header">
                Card Header
            </slot>
        <v-card-title class="d-flex justify-center">
            <h3 class='text-center text-h4 font-weight-light'>
                {{ title }}
            </h3>
        </v-card-title>
    </v-card>
</template>

<script>
    export default {
        name: "CustomCard",
        props: {
            title: {
                type: String,
                default: 'Placeholder'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>